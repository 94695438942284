import { render, staticRenderFns } from "./product-category-schedule-form.vue?vue&type=template&id=671410ee&"
import script from "./product-category-schedule-form.vue?vue&type=script&lang=js&"
export * from "./product-category-schedule-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports