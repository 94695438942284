<script>
import CustomTable from "../../../../components/tables/custom-table.vue";
import ProductCategoryScheduleForm from "../forms/product-category-schedule-form";
import AddButton from "../../../../components/buttons/add-button.vue";
import Months from "../../../../helpers/general/months";
import DaysOfWeek from "../../../../helpers/general/days-of-week";

/**
 * Customers Component
 */
export default {
    props: {
      customer_task_id: Number
    },
  components: {
    CustomTable,
    ProductCategoryScheduleForm,
    AddButton
  },
  data() {
    return {
      formModalId:'product-category-schedule-show-form-modal',
      table:{
        url:'/product-category-schedules',        
        fields:[
          { key: "id", label: "Cod", sortable: false },
          { key: "frequency", label: "Frequency", sortable: false },
          { key: "month", label: "Month", sortable: false },
          { key: "day_of_month", label: "Day of month", sortable: false },
          { key: "day_of_week", label: "Day of week", sortable: false },
          { key: "hour", label: "Hour", sortable: false },
          { key: "minute", label: "Minute", sortable: false },
        ],
        formatted_fields:[
          'month',
          'day_of_week',
        ]
      }, 
      formSelectedItemId:null,   
    };
  },
  computed:{
    params(){
      return {customer_task_id: this.customer_task_id};
    }
  },
  methods: {
    showEditModal(item) {
      this.formSelectedItemId = item?.id || null;
      this.$bvModal.show(this.formModalId);
    },
    onSuccess(){
      this.$refs.productCategoryScheduleShowTable.refresh();
    },
    formatter(field, item){
      switch(field){
        case 'month':
        {
          return Months.getMonth(item?.month);
        }
        case 'day_of_week':
        {
          return DaysOfWeek.getDayOfweek(item?.day_of_week);
        }
      }

      return '';
    }
  }
};
</script>

<template>
  <div class="card">
      <div class="card-body">
          <ProductCategoryScheduleForm :modal-id="formModalId" :product-category-id="customer_task_id" :id="formSelectedItemId" @onSuccess="onSuccess"></ProductCategoryScheduleForm>
          <h4 class="card-title mb-4">Task Schedules 
            <div class="text-right">
              <AddButton @click="showEditModal"></AddButton>
            </div>
          </h4>
          
          <CustomTable
            :url="table.url"
            :fields="table.fields"   
            :formatted-fields="table.formatted_fields"
            :formatter="formatter"
            @edit="showEditModal"        
            :params="params"   
            :show-delete="true"    
            :containerTableClass="'max-h-250'"
            ref="productCategoryScheduleShowTable"
          >              
          </CustomTable>
      </div>
  </div>
</template>