<script>
import { required, requiredIf, minValue, maxValue } from "vuelidate/lib/validators";
import Modal from "../../../../components/modals/modal-form.vue";
import api from '../../../../helpers/apirest/api';
import Months from "../../../../helpers/general/months";
import DaysOfWeek from "../../../../helpers/general/days-of-week";

const initialState = () => {
    return {
        submitted: false,
        customer_task_schedule: {
            customer_task_id: '',
            frequency: '',
            month: '',
            day_of_month: '',
            day_of_week: '',
            hour: '',
            minute: '',
        },
        frequencies: [
            {
                id: 'hourly', label: 'Hourly'
            },
            {
                id: 'daily', label: 'Daily'
            },
            {
                id: 'weekly', label: 'Weekly'
            },
            {
                id: 'monthly', label: 'Monthly'
            },
            {
                id: 'yearly', label: 'Yearly'
            }
        ]
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number,
        productCategoryId: Number
    },
    data() {
        return {
            months: Months.getMonths(),
            daysOfWeek: DaysOfWeek.getDaysOfweek(),
            ...initialState()
        };
    },
    components: {
        Modal
    },
    computed: {
        withMonth(){
            const allowFrequencies = ['yearly'];
            return allowFrequencies.includes(this?.customer_task_schedule?.frequency);            
        },
        withDayOfMonth(){
            const allowFrequencies = ['yearly', 'monthly'];
            return allowFrequencies.includes(this?.customer_task_schedule?.frequency);            
        },
        withDayOfWeek(){
            const allowFrequencies = ['weekly'];
            return allowFrequencies.includes(this?.customer_task_schedule?.frequency);            
        },
        withHour(){
            const allowFrequencies = ['daily', 'weekly', 'monthly', 'yearly'];
            return allowFrequencies.includes(this?.customer_task_schedule?.frequency);            
        },
    },
    validations: {
        customer_task_schedule: {
            customer_task_id: { required },
            frequency: { required },
            month: {
                required: requiredIf(function() { 
                    return this.withMonth 
                }), 
            },
            day_of_month: {
                required: requiredIf(function() { 
                    return this.withDayOfMonth
                }),
                minValue: minValue(1),
                maxValue: maxValue(31),
            },
            day_of_week: {
                required: requiredIf(function() { 
                    return this.withDayOfWeek 
                }),
            },
            hour: {
                required: requiredIf(function() { 
                    return this.withHour 
                }),
                minValue: minValue(0),
                maxValue: maxValue(23),
            },
            minute: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(59),
            }
        }
    },
    methods: {
        async shown() {
            if (this.id) {
                const response = await api.get({
                    url: `product-category-schedules/${this.id}`
                });
                this.customer_task_schedule = response?.data?.data;
            }else{
                this.customer_task_schedule.customer_task_id = this.productCategoryId;
            }
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            const data = this.customer_task_schedule;

            await api.save({
                url: 'product-category-schedules',
                id: this.customer_task_schedule?.id,
                data: data 
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        }
    }
};
</script>

<template>
    <Modal :title="'Customer task schedule form'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal">
        <div class="form-group">
            <label for="frequency">Frequency</label>
            <select v-model="customer_task_schedule.frequency" class="form-control"
                :class="{ 'is-invalid': $v.customer_task_schedule.frequency.$error && submitted }">
                <option value="">Select</option>
                <option v-for="option in frequencies" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
            <div v-if="!$v.customer_task_schedule.frequency.required && submitted" class="invalid-feedback">Frequency
                is
                required</div>
        </div>
        <div class="form-group" v-if="withMonth">
            <label for="month">Month</label>
            <select v-model="customer_task_schedule.month" class="form-control"
                :class="{ 'is-invalid': $v.customer_task_schedule.month.$error && submitted }">
                <option value="">Select</option>
                <option v-for="option in months" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
            <div v-if="!$v.customer_task_schedule.month.required && submitted" class="invalid-feedback">Month
                is
                required</div>
        </div>
        <div class="form-group" v-if="withDayOfWeek">
            <label for="day_of_week">Day of week</label>
            <select v-model="customer_task_schedule.day_of_week" class="form-control"
                :class="{ 'is-invalid': $v.customer_task_schedule.day_of_week.$error && submitted }">
                <option value="">Select</option>
                <option v-for="option in daysOfWeek" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
            <div v-if="!$v.customer_task_schedule.day_of_week.required && submitted" class="invalid-feedback">Day of week
                is
                required</div>
        </div>
        <div class="form-group" v-if="withDayOfMonth">
            <label for="day_of_month">Day of month</label>
            <input id="day_of_month" v-model="customer_task_schedule.day_of_month" type="number" class="form-control"
                placeholder="" :class="{ 'is-invalid': $v.customer_task_schedule.day_of_month.$error && submitted }" />
            <span v-if="!$v.customer_task_schedule.day_of_month.required && submitted" class="invalid-feedback">Day of month is
                required.</span>
            <span v-else-if="!$v.customer_task_schedule.day_of_month.minValue && submitted" class="invalid-feedback">Min value: {{
                    $v.customer_task_schedule.day_of_month.$params.minValue.min }}.</span>
            <span v-else-if="!$v.customer_task_schedule.day_of_month.maxValue && submitted" class="invalid-feedback">Max value: {{
                    $v.customer_task_schedule.day_of_month.$params.maxValue.max }}.</span>
        </div>
        <div class="form-row align-items-center">
            <div class="col-sm-6 my-1" v-if="withHour">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">Hour</div>
                    </div>
                    <input id="hour" v-model="customer_task_schedule.hour" type="number" class="form-control"
                        placeholder="" :class="{ 'is-invalid': $v.customer_task_schedule.hour.$error && submitted }" />
                    <span v-if="!$v.customer_task_schedule.hour.required && submitted" class="invalid-feedback">Hour is
                        required.</span>
                    <span v-else-if="!$v.customer_task_schedule.hour.minValue && submitted" class="invalid-feedback">Min value: {{
                            $v.customer_task_schedule.hour.$params.minValue.min }}.</span>
                    <span v-else-if="!$v.customer_task_schedule.hour.maxValue && submitted" class="invalid-feedback">Max value: {{
                            $v.customer_task_schedule.hour.$params.maxValue.max }}.</span>
                </div>
            </div>
            <div class="col-sm-6 my-1">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">Minute</div>
                    </div>
                    <input id="minute" v-model="customer_task_schedule.minute" type="number" class="form-control"
                        placeholder="" :class="{ 'is-invalid': $v.customer_task_schedule.minute.$error && submitted }" />
                    <span v-if="!$v.customer_task_schedule.minute.required && submitted" class="invalid-feedback">Minute is
                        required.</span>
                    <span v-else-if="!$v.customer_task_schedule.minute.minValue && submitted" class="invalid-feedback">Min value: {{
                            $v.customer_task_schedule.minute.$params.minValue.min }}.</span>
                    <span v-else-if="!$v.customer_task_schedule.minute.maxValue && submitted" class="invalid-feedback">Max value: {{
                            $v.customer_task_schedule.minute.$params.maxValue.max }}.</span>
                </div>
            </div>
        </div>
</Modal></template>